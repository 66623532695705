import { IServicePageResponse } from '../../../api.interface'

export default <IServicePageResponse>{
  serviceType: 'handyman',
  formId: 'DK%20Handyman',
  title: 'Handyman',
  subTitle: 'Nothing is impossible with the right attitude… and a hammer.',
  icon: '/icons/maintenance.svg',
  image: '/assets/images/sunrise/handyman_supermercat.png',
  theme: 'sunrise-handyman',
  sections: [
    {
      sectionId: 'hero',
      label: 'Handyman',
      title: 'Nothing is impossible with the right attitude… and a hammer.',
      description:
        'Ten thumbs? No problem! Get access to a very wide selection of trained handymen. Also if you need electricity, plumbing or other specific stuff done then look no further.',
      image: '/assets/images/sunrise/handyman_supermercat.png',
      icon: '/icons/handyman.svg',
      buttonText: 'Request a proposal',
    },
    {
      sectionId: 'options',
      title: 'A handyman is not a one trick pony. It’s a Swiss knife!',
      description: '',
      optionsItems: [
        {
          icon: '/assets/images/services/handyman/service_1.svg',
          title: 'Assemble furniture',
          description: 'Everything from desks to cupboards. Our handymen have the will... the will to drill.',
        },
        {
          icon: '/assets/images/services/handyman/service_2.svg',
          title: 'Fix broken things',
          description: 'We can fix everything except a broken heart. Unless that’s a service you really need.',
        },
        {
          icon: '/assets/images/services/handyman/service_3.svg',
          title: 'Hang things',
          description:
            'Not a hammer or a nail in sight?  We’ll hang your group photo and mount your tv screens so you can show off your dazzling dashboards.',
        },
        {
          icon: '/assets/images/services/handyman/service_4.svg',
          title: 'Move things around',
          description: 'Rearranging the office or just have some heavy lifting to do? We’ll bring the muscles.',
        },
      ],
    },
    {
      sectionId: 'journey',
      title: 'Getting a handyman is super easy',
      description: '',
      journeyItems: [
        {
          icon: '/assets/images/services/handyman/journey_1.svg',
          title: 'Tell us what you need',
          description: 'Explain what you need a handyman for and when.',
        },
        {
          icon: '/assets/images/services/handyman/journey_2.svg',
          title: 'Receive proposal',
          description: 'Get a price and a date for when our handyman can come by.',
        },
        {
          icon: '/assets/images/services/handyman/journey_3.svg',
          title: 'Accept!',
          description: 'It’s that easy. Lean back and enjoy the day.',
        },
      ],
    },
    {
      sectionId: 'benefits',
      title: 'Why a handyman from Good Monday is the right choice for you!',
      description: '',
      image: '/assets/images/services/handyman/benefits_illustration.svg',
      benefitsItems: [
        {
          icon: 'eva eva-calendar-outline',
          title: 'Flexible',
          description: 'We’ll work around your schedule to make sure timing is right and on point.',
        },
        {
          icon: 'eva eva-flash-outline',
          title: 'Fast',
          description: 'We have a large network and can handle urgent assignments with lightening speed.',
        },
        {
          title: 'Value for money',
          description: 'The best and the fastest. Get great value and high quality.',
        },
      ],
    },
    {
      sectionId: 'cta',
      title: 'Did you know that you can get a handyman on subscription instead of ordering when it’s too late?',
      description: '',
      image: '/assets/images/services/cta_illustration.svg',
      buttonText: 'Request a proposal',
    },
  ],
}
